<template>
  <div>
    <el-table border :data="list">
      <el-table-column prop="goods_id" label="编号"></el-table-column>
      <el-table-column prop="goods_title" label="标题"></el-table-column>
      <el-table-column prop="goods_price" label="价格"></el-table-column>
      <el-table-column>
        <template #header>
          <el-button @click="chooseBox=true" size="small">选择商品</el-button>
        </template>
        <template #default="s">
          <el-button @click="dose({id:s.row.goods_id})" size="small">移除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="选择商品" :visible.sync="chooseBox">
      <div class="flex-def flex-cCenter" style="margin-bottom: 1rem">
        <el-input style="width: 20rem;margin-right: 1rem" v-model="searchForm.title"></el-input>
        <el-button-group>
          <el-button @click="searchGo" type="primary">搜索</el-button>
          <el-button @click="searchReset">重置</el-button>
        </el-button-group>
      </div>
      <el-table border :data="wait_list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="price" label="价格"></el-table-column>
        <el-table-column>
          <template #default="s">
            <el-button @click="dose(s.row)" size="small">{{elDoseTitle(s.row)}}</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="wait_total" :page-size="15" :current-page="wait_page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "goods",
  props:{
    custom_id:{
      type:Number,
      default:0
    }
  },
  data(){
    return{
      list:[],
      wait_list:[],
      wait_page:1,
      wait_total:0,
      chooseBox:false,
      searchForm:{
        title:""
      }
    }
  },
  mounted() {
    this.load();
    this.loadWait();
  },
  methods:{
    elDoseTitle(item){
      let arr = this.list.filter(e=>e.goods_id === item.id)
      return  arr.length > 0 ? '移除' :'加入'
    },
    pageChange(e) {
      this.wait_page = e;
      this.loadWait();
    },
    searchReset(){
      this.searchForm.title = "";
      this.searchGo();
    },
    searchGo(){
      this.wait_page = 1;
      this.wait_list.length = 0;
      this.loadWait()
    },
    dose(item){
      this.$api.plugin.shop.designGoodsDose({
        custom_id: this.custom_id,
        goods_id:item.id
      }).then(()=>{
        this.$message.success("操作成功")
        this.load();
      })
    },
    loadWait(){
      this.$api.plugin.shop.goodsSearch({
        page:this.wait_page,
        title:this.searchForm.title,
      }).then(res=>{
        this.wait_list = res.list;
        this.wait_total = res.total;
      })
    },
    load(){
      this.$api.plugin.shop.designGoodsAll({
        id:this.custom_id
      }).then(res=>{
        this.list = res;
      })
    },
  }
}
</script>

<style scoped>

</style>