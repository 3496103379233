<template>
  <el-tabs type="border-card">
    <el-tab-pane label="口令管理" >
      <div class="flex-def flex-zBetween" style="margin-bottom: 1rem">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <el-input v-model="search" size="medium" placeholder="输入口令搜索"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button-group>
              <el-button @click="searchCode" size="medium" type="primary" icon="el-icon-search">搜索</el-button>
              <el-button @click="searchReset" size="medium" native-type="reset">重置</el-button>
            </el-button-group>
          </el-form-item>
        </el-form>
      </div>
      <el-table v-loading="loading" :data="list" border style="width: 100%">
        <el-table-column prop="id" label="编号" width="80"></el-table-column>
        <el-table-column prop="code" label="口令"></el-table-column>
        <el-table-column prop="grant_uid" label="用户编号"></el-table-column>
        <el-table-column prop="grant_order_id" label="订单编号"></el-table-column>
        <el-table-column label="颁发时间">
          <template #default="s">{{s.row.grant_time | date}}</template>
        </el-table-column>
        <el-table-column label="生成时间">
          <template #default="s">{{s.row.created_at | date}}</template>
        </el-table-column>
        <el-table-column width="80" label="操作">
          <template #default="s">
            <el-button @click="delCode(s.$index)" size="mini">删除</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="size" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="生成口令">
      <el-form label-width="100px" inline>
        <el-form-item label="前缀">
          <el-input v-model="makeForm.pre"></el-input>
        </el-form-item>
        <el-form-item label="生成数量">
          <el-input v-model="makeForm.num"></el-input>
        </el-form-item>
        <el-form-item label="口令长度">
          <el-input v-model="makeForm.long"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="makeFormSubmit" type="primary" >生成</el-button>
        </el-form-item>
      </el-form>
    </el-tab-pane>
    <el-tab-pane label="导入口令">
      <el-form label-width="200px">
        <el-form-item label="填入入群码每行一条">
          <el-input :rows="4" type="textarea" style="width: 40rem" v-model="importForm.codes"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="importFormSubmit" type="primary" >导入</el-button>
        </el-form-item>
      </el-form>
    </el-tab-pane>
    <el-tab-pane label="口令发放">
      <el-form label-width="100px">
        <el-form-item label="入群口令功能">
          <el-switch v-model="form.group_sign"></el-switch>
        </el-form-item>
        <el-form-item label="入群二维码">
          <yUploadImg v-model="form.group_qrcode" style="width: 20rem"></yUploadImg>
        </el-form-item>
        <el-form-item label="入群弹窗标题">
          <el-input v-model="form.group_box_title" style="width: 20rem"></el-input>
        </el-form-item>
        <el-form-item label="入群弹窗描述">
          <el-input v-model="form.group_box_desc" style="width: 20rem"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="submit" type="primary" >保存</el-button>
        </el-form-item>
      </el-form>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import yUploadImg from "@/components/y_upload_img";
export default {
  name: "code",
  components:{yUploadImg},
  model:{
    prop:"modelVal",
    event:'change'
  },
  props:{
    modelVal:{
      type:Object,
      default(){
        return{
          group_sign:false,
          group_qrcode:"",
          group_box_title:"",
          group_box_desc:"",
        }
      }
    },
  },
  data(){
    return{
      page:1,
      size:15,
      total:0,
      list:[],
      search:"",
      makeForm:{
        pre:"",
        num:0,
        long:24,
      },

      importForm:{
        codes:"",
      },
      form:this.modelVal,
      loading:false,
    }
  },
  watch:{
    modelVal(){
      this.form = this.modelVal;
    }
  },
  mounted() {
    this.loadCodePage();
  },
  methods:{
    submit(){
      this.$emit("submit")
    },
    importFormSubmit(){
      this.$api.plugin.shop.designSignCodeImport({
        custom_id:parseInt(this.$route.query.id),
        codes:this.importForm.codes
      }).then(()=>{
        this.importForm.codes = "";
        this.$message.success("操作成功");
        this.loadCodePage();
      })
    },
    delCode(index){
      let item = this.list[index];
      this.$api.plugin.shop.designSignCodeDel({
        id:item.id
      }).then(()=>{
        this.$message.success("操作成功");
        this.loadCodePage();
      })
    },
    searchReset(){
      this.search = "";
      this.page = 1;
      this.loadCodePage();
    },
    searchCode(){
      this.page = 1;
      this.loadCodePage();
    },
    makeFormSubmit(){
      this.makeForm.custom_id = parseInt(this.$route.query.id);
      this.makeForm.num = parseInt(this.makeForm.num);
      this.makeForm.long = parseInt(this.makeForm.long);
      if (this.makeForm.num <= 0){
        this.$message.error("生成数量不能为0");
        return;
      }
      this.$api.plugin.shop.designSignCodeMake(this.makeForm).then(()=>{
        this.$message.success("操作成功");
        this.loadCodePage();
      })
    },
    pageChange(e){
      this.page = e;
      this.loadCodePage();
    },
    loadCodePage(){
      this.loading = true;
      this.$api.plugin.shop.designSignCodeSearch({
        page:this.page,
        custom_id:parseInt(this.$route.query.id),
        code:this.search
      }).then(res=>{
        this.loading = false;
        this.list = res.list;
        this.total =res.total;
      })
    }
  }
}
</script>

<style scoped>

</style>