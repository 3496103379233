<template>
  <div>
    <el-row :gutter="40">
      <el-col :span="12">
        <el-form label-width="6rem">
          <el-form-item label="页面标题">
            <el-input v-model="form.page_title"></el-input>
          </el-form-item>
          <el-form-item label="分享标题">
            <el-input v-model="form.share_title"></el-input>
          </el-form-item>
          <el-form-item label="分享描述">
            <el-input v-model="form.share_desc"></el-input>
          </el-form-item>
          <el-form-item label="分享图片">
            <y_upload_img v-model="form.share_pic"></y_upload_img>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12">
        <el-form label-width="8rem">
          <el-form-item label="背景色">
            <y_color_choose v-model="form.bg_color"></y_color_choose>
          </el-form-item>
          <el-form-item label="背景音乐">
            <y_upload_audio v-model="form.bg_audio"></y_upload_audio>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import y_upload_img from "@/components/y_upload_img";
import y_color_choose from "@/components/y_color_choose";
import y_upload_audio from "@/components/y_upload_audio";
export default {
  name: "basic",
  components:{
    y_upload_img,
    y_color_choose,
    y_upload_audio,
  },
  model:{
    prop:"modelVal",
    event:'change'
  },
  props:{
    modelVal:{
      type:Object,
      default(){
        return{
          bg_color:"",
          bg_audio:"",
          page_title:"",
          share_title:"",
          share_desc:"",
          share_pic:"",
        }
      }
    },
  },
  watch:{
    modelVal(){
      this.form = this.modelVal;
    }
  },
  data(){
    return{
      form:this.modelVal,
    }
  },
  methods:{
    submit(){
      this.$emit("submit")
    },
  }
}
</script>

<style scoped>

</style>